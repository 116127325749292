const listEnvs = process.env;

export default {
  get(key: string): string | number | boolean | undefined {
    if (listEnvs[key] === undefined) return undefined;

    // Пробуем привести к типу
    let exportValue;
    try {
      exportValue = JSON.parse(listEnvs[key]);
    } catch (err) {
      exportValue = listEnvs[key];
    }
    return exportValue;
  },
};
